import type { CurrentUserQuery } from '$lib/queries/generated/currentUser'
import type { User } from 'firebase/auth'
import { get, writable, type Readable } from 'svelte/store'

export type CurrentUser = CurrentUserQuery['currentUser']
export type SignInState =
  | 'signIn'
  | 'signUp'
  | 'joinWaitlist'
  | 'subscribe'
  | 'reserve'
  | 'discount'
export type AuthCallback = (err: Error | null) => void | Promise<void>

export type AuthState = {
  authDidInitialize: boolean
  authErrorMessage: string
  signedIn: boolean
  currentUser: CurrentUser | null
  authenticating: boolean
  showEmailConfirmation: boolean
  isCompletingSignIn: boolean

  authCallbacks: AuthCallback[]
}

function createInitialState() {
  return {
    authDidInitialize: false,
    authErrorMessage: null,
    // Authentication state
    signedIn: false,
    currentUser: null,
    authenticating: false,

    // Email OTP flow state
    showEmailConfirmation: false,
    isCompletingSignIn: false, // replace with `authenticating`
    authCallbacks: [],
  }
}

const store = writable<AuthState>(createInitialState())
const set = (newState: Partial<AuthState>) => store.update((state) => ({ ...state, ...newState }))

const setCurrentUser = (currentUser: CurrentUser | null) =>
  set({
    currentUser,
    signedIn: currentUser !== null,
    authenticating: false,
    authErrorMessage: null,
    authDidInitialize: true,
    isCompletingSignIn: false,
    showEmailConfirmation: false,
  })

const resolveAuthCallbacks = async (err: Error | null) => {
  const currentState = get(state)
  const cbs = currentState.authCallbacks || []
  for (const cb of cbs.filter(Boolean)) {
    await cb(err)
  }

  store.set({ ...currentState, authCallbacks: [] })
}

const addAuthCallback = (cb: AuthCallback) => {
  store.update((state) => {
    const cbs = state.authCallbacks || []
    cbs.push(cb)
    return { ...state, authCallbacks: cbs }
  })
}

const setAuthError = (msg: string) => set({ authErrorMessage: msg, signedIn: false })
const clearAuthError = () => set({ authErrorMessage: null })
const setAuthenticating = (authenticating: boolean) => set({ authenticating, signedIn: false })
const setInitialized = () => set({ authDidInitialize: true })
const clearCurrentUser = () =>
  set({
    currentUser: null,
    signedIn: false,
    authenticating: false,
    // authCallbacks: [],
    authDidInitialize: false,
  })

const setShowEmailConfirmation = (showEmailConfirmation: boolean) => set({ showEmailConfirmation })

const loadCurrentUser = async (user: User) => {
  console.log('[AUTH] Loading current user')
  const currentUser: CurrentUser = {
    id: user.uid,
    email: user.email,
    emailVerified: user.emailVerified,
    fullName: user.displayName,
    pictureURL: user.photoURL,
    airheartDeveloper: false,
    intercomSettings: null,
    __typename: 'User',
  }

  set({
    authenticating: false,
    signedIn: true,
    currentUser,
    authDidInitialize: true,
    authErrorMessage: null,
    isCompletingSignIn: false,
    showEmailConfirmation: false,
  })
  return currentUser
}

const state: Readable<AuthState> & {
  setCurrentUser: typeof setCurrentUser
  addAuthCallback: typeof addAuthCallback
  resolveAuthCallbacks: typeof resolveAuthCallbacks
  setAuthenticating: typeof setAuthenticating
  setInitialized: typeof setInitialized
  clearCurrentUser: typeof clearCurrentUser
  setShowEmailConfirmation: typeof setShowEmailConfirmation
  loadCurrentUser: typeof loadCurrentUser
  setAuthError: typeof setAuthError
  clearAuthError: typeof clearAuthError
} = {
  subscribe: store.subscribe,
  setCurrentUser,
  addAuthCallback,
  resolveAuthCallbacks,
  setAuthenticating,
  setInitialized,
  clearCurrentUser,
  setShowEmailConfirmation,
  loadCurrentUser,
  setAuthError,
  clearAuthError,
}

export default state
