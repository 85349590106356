import type { PriceLineItem } from '$lib/gen/rates/v1/rate_pb'
import type { Country } from '$lib/graphql/types'
import type { CheckInInstructionsFieldsFragment } from '$lib/queries/fragments/generated/CheckInInstructionsFields'
import type { GuestFieldsFragment } from '$lib/queries/fragments/generated/GuestFields'
import type { HotelCardFieldsFragment } from '$lib/queries/fragments/generated/HotelCardFields'
import type { PhotoFieldsFragment } from '$lib/queries/fragments/generated/PhotoFields'
import type { SearchLocationFieldsFragment } from '$lib/queries/fragments/generated/SearchlocationFields'
import type { GroupedAmenities } from 'src/utils/stays/amenities'
import type { MaxOccupancy } from 'src/utils/stays/occupancy'
import { get, writable, type Readable } from 'svelte/store'
import state, { type SignInState } from './auth-state'

export type ModalType =
  | 'share'
  | 'countries'
  | 'login'
  | 'signup'
  | 'confirm-email'
  | 'search'
  | 'sherpa-iframe'
  | 'add-hotel'
  | 'add-flight-slice'
  | 'date-select'
  | 'delete-trip'
  | 'guest-select'
  | 'guest-select-hotel-page'
  | 'publish-trip'
  | 'add-location'
  | 'room-select'
  | 'timeline-item'
  | 'room-item'
  | 'highlights'
  | 'trip-panel'
  | 'cancellation-policy'
  | 'checkin-instructions'
  | 'hotel-filters'
  | 'hotel-photos'
  | 'room-details'
  | 'calendar'
  | 'calendar-hotel-page'
  | 'guest-select'
  | 'location-search'
  | 'cancellation-policy'
  | 'confirm-cancel'
  | 'checkin-policy'
  | 'change-origin'
  | 'map-search'
  | 'price-breakdown'
  | 'hotel-amenities'
  | 'resend-confirmation'
  | 'localization-select'
  | null

type ExtraTypeMap = Record<ModalType, unknown> & {
  'sherpa-iframe': string
  countries: Pick<Country, 'name'>[]
  signup: { intent: SignInState; callback?: AuthCallback }
  'hotel-photos': {
    hotelId: string
    photoIndex: number
    allPhotos: PhotoFieldsFragment[]
    isRoom: boolean
    isFetching: boolean
  }
  'guest-select': {
    maxOccupancy: MaxOccupancy
  }
  'guest-select-hotel-page': {
    maxOccupancy: MaxOccupancy
  }
  'cancellation-policy': {
    penalties: string[]
  }
  'checkin-policy': {
    checkInDetails: CheckInInstructionsFieldsFragment
  }
  'hotel-filters': {
    isFullScreen: boolean
  }
  'hotel-amenities': {
    groupedAmenities: GroupedAmenities[]
  }
  'confirm-cancel': {
    reservationId: string
    referenceNumber: string
    penalties: string[]
  }
  'resend-confirmation': {
    reservationId: string
    guests: GuestFieldsFragment[]
  }
  'change-origin': {
    originId: string
  }
  'map-search': {
    hotels: HotelCardFieldsFragment[]
    numNights: number
    destination: SearchLocationFieldsFragment
    mapSearchCoordinates: {
      neLat: number
      neLng: number
      swLat: number
      swLng: number
    }
  }
  'price-breakdown': {
    quotePriceItems: PriceLineItem[]
    totalAmount: number
    totalPropertyFees: number
  }
}

export type AuthCallback = (err: Error | null) => void

const store = writable<ModalType>(null)
const extraStore = writable<Partial<ExtraTypeMap>>(null)

const open = <T extends ExtraTypeMap, K extends keyof ExtraTypeMap>(type: K, extra?: T[K]) => {
  store.set(type)
  if (extra) {
    extraStore.set({ [type]: extra })
    if (typeof extra['callback'] === 'function') {
      state.addAuthCallback(extra['callback'])
    }
  } else {
    extraStore.set({ [type]: null })
  }
}

const close = () => {
  store.set(null)
}

function getExtra<T extends ExtraTypeMap, K extends keyof ExtraTypeMap>(modalType: K): T[K] {
  return get(extraStore)[modalType] as T[K]
}

export { extraStore as extra }

const modal: Readable<ModalType> & {
  open: typeof open
  close: typeof close
  extra: typeof getExtra
} = {
  subscribe: store.subscribe,
  open,
  close,
  extra: getExtra,
}

export default modal
